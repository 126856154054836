<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="showModal"
    >   
        <CForm id="customers_form" method="POST" enctype="multipart/form-data">
            <CRow>
              <CCol md="12">
                <CInput
                  label="Archivo a Importar:"
                  name="file"
                  id="file"
                  type="file"
                  :lazy="false"
                  :value.sync="$v.form.file.$model"
                  placeholder=""
                  autocomplete="given-name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                  required
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="importSales" :color="color">Importar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import ws from '../../services/customers';
import store from '../../store'

export default {
  name: 'ImportCustomersModal',
  props: {

  },
  data () {
    return {
      showModal: false,
      title: "Importar Clientes",
      color: "success",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ]
    }
  },
  mounted: async function() {
    
  },
  methods: {
    importModal () {
      this.showModal = true;
      this.color = "success";
      this.title = 'Importar Ventas';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          id: 0,
          file: ''
      }
    },
    async importSales () {
      this.loading();
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        const form = document.getElementById("customers_form");
        const formData = new FormData(form);

        let response = await ws.import(formData);

        if(response.type == "success"){
          this.$emit("import", response.data);
          this.closeModal();
        }

        this.showToast(response.type, response.message);

        this.loaded();
      }
      else{
        this.loaded();
        this.showToast("error", "Debes ingresar un archivo XLSX o CSV para poder continuar.");
      }
    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          file: '',
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      file: {
        required
      }
    }
  },
}
</script>